import * as React from 'react';
import { Route, Switch } from 'react-router';
import loadable from '@loadable/component';

import { PageLoaderFallback } from '../../layout/PageLoaderFallback';
import { ApolloWrapper } from '../../wrapper/Apollowrapper';

const BrokerContact = loadable(
  () => import(/* webpackPrefetch: true */ './contact/Index'),
  { fallback: <PageLoaderFallback /> }
);
const BrokerIndex = loadable(
  () => import(/* webpackPrefetch: true */ './index/Index'),
  { fallback: <PageLoaderFallback /> }
);
const NoMatch = loadable(
  () => import(/* webpackPrefetch: true */ '../404Old'),
  { fallback: <PageLoaderFallback /> }
);

const Broker: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/megler/:path/kontakt" component={BrokerContact} />
      <Route
        exact
        path="/megler/:path"
        render={(props: any) => {
          return (
            <ApolloWrapper>
              <BrokerIndex {...props} />
            </ApolloWrapper>
          );
        }}
      />
      <Route component={NoMatch} />
    </Switch>
  );
};

export default Broker;
